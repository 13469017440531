.title {
  font-size: 36px;
  text-align: center;
  text-shadow: 1px 2px 6px rgba(0, 0, 0, 0.5);
}

.timeline-box {
  padding: 10% 20px 100px 20px;
  /* z-index: -2; */
}
.phone-description {
  font-size: 20px;
  /* z-index: -2; */
}

.timeline-container {
  position: relative; /* Allow positioning of the line */
  z-index: -2;
}

.timeline-line {
  position: absolute; /* Position the line independently */
  top: 0;
  bottom: 0;
  left: 50%; /* Adjust as needed to center the line */
  width: 3px;
  background-color: #33475b; /* Adjust color as desired */
  z-index: -3;
}
