/* src/index.css */
body {
  /* background-color: #f5f5f5; */
  background: linear-gradient(to bottom, #f5f5f5, #4caf50);
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.grid-container {
  display: grid;
  grid-template-columns: 5% 55% 40%;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.grid-Socialicons {
  /* position: relative; */
  grid-column: 1;
}

.grid-EyesComponent {
  z-index: -1;
  grid-column: 3;
  padding: 10% 0px 0px 0px;
  grid-row: 1;
}

.grid-TypingComponent {
  grid-column: 2;
  padding: 20% 20px 255px 20px;
  font-size: 30px;
  text-align: left;
  grid-row: 1;
}

.grid-strengths {
  grid-column: 2 / 4;
  grid-row: 2;
}

.grid-Timeline {
  padding: 50px 0px 10px 0px;
  grid-column: 2 / 4;
  grid-row: 3;
}
.grid-Projects {
  padding: 50px 0px 10px 0px;
  grid-column: 2 /4;
  grid-row: 4;
}
.grid-Contactus {
  padding: 50px 0px 30px 0px;
  grid-column: 2 /4;
  grid-row: 5;
}

/* padding: top .... bottom ....; */
/* padding: 20% 20px 255px  20px; */

@media only screen and (max-width: 768px) {
  .grid-container {
    grid-template-columns: 100%;
  }

  .grid-EyesComponent {
    grid-column: 1;
    grid-row: 1;
    right: 0%;
    padding: 10% 20px 100% 20px;
  }
  .grid-Socialicons {
    z-index: -1;
    grid-column: 1;
    grid-row: 2;
    padding: 10px 20px 10px 20px;
    text-align: center;
  }
  .grid-TypingComponent {
    grid-column: 1;
    grid-row: 3;
    padding: 5px 20px 80px 20px;
  }
  .grid-strengths {
    grid-column: 1;
    grid-row: 4;
  }
  .grid-Timeline {
    grid-column: 1;
    grid-row: 5;
    padding: 5px 20px 80px 20px;
    font-size: 30px;
    text-align: center;
  }
  .grid-Projects {
    grid-column: 1;
    grid-row: 6;
  }
  .grid-Contactus {
    grid-column: 1;
    grid-row: 7;
  }
}
