/* FaceComponent.css */

.Container {
  position: relative;
  padding: auto;
  align-items: center;
}

.face-image,
.eyes-image,
.body-image {
  max-width: 100%;
  position: absolute;
}
.face-image {
  z-index: 2;
}
.eyes-image {
  z-index: 3;
}

.body-image {
  transform: translateY(-7%);
}
