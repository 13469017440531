/* Strengths.css */
.grid-Cotainer {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
}

.title {
  font-size: 30px;
  grid-column: 1 / 5;
  text-align: center;
}

/* These are my images */
.cplus,
.react,
.python,
.pandas {
  grid-row: 2;
  max-width: 100%;
}

.cplus {
  grid-column: 1;
}
.react {
  grid-column: 2;
}
.python {
  grid-column: 3;
}
.pandas {
  grid-column: 4;
}

/* These are text under my images */
.CplusTitle,
.reactTitle,
.pythonTitle,
.pandasTitle {
  grid-row: 3;
  text-align: center;
  font-size: 26px;
  text-shadow: 1px 1px 6px rgba(0, 0, 0, 0.3);
  font-weight: bolder;
}

.CplusTitle {
  grid-column: 1;
}
.reactTitle {
  grid-column: 2;
}
.pythonTitle {
  grid-column: 3;
}
.pandasTitle {
  grid-column: 4;
}

/* These are text under my images */
.CplusTime,
.reactTime,
.pythonTime,
.pandasTime {
  grid-row: 4;
  text-align: center;
  font-size: 24px;
  text-shadow: 1px 1px 6px rgba(0, 0, 0, 0.3);
  font-weight: 100;
}

.CplusTime {
  grid-column: 1;
}
.reactTime {
  grid-column: 2;
}
.pythonTime {
  grid-column: 3;
}
.pandasTime {
  grid-column: 4;
}

@media only screen and (max-width: 768px) {
  .grid-Cotainer {
    grid-template-columns: 50% 50%;
  }

  .react,
  .reactTitle,
  .reactTime,
  .cplus,
  .CplusTime,
  .CplusTitle {
    grid-column: 1;
  }

  .pandas,
  .pandasTime,
  .pandasTitle,
  .python,
  .pythonTime,
  .pythonTitle {
    grid-column: 2;
  }

  .react,
  .pandas {
    grid-row: 1;
  }
  .reactTitle,
  .pandasTitle {
    grid-row: 2;
  }

  .reactTime,
  .pandasTime {
    grid-row: 3;
  }
  .cplus,
  .python {
    grid-row: 4;
  }
  .CplusTitle,
  .pythonTitle {
    grid-row: 5;
  }
  .CplusTime,
  .pythonTime {
    grid-row: 6;
  }
}
